<template>
  <div>
    <passContainer
      :sections="sections"
      :activeSection="activeSection"
    ></passContainer>
    <titleForm
      title="Creando un nuevo Usuario en la sede"
      :strong="branchName"
    ></titleForm>
    <form
      id="userForm"
      class="mainForm mb-3 needs-validation"
      v-bind:class="{ 'was-validated': showValidate }"
    >
      <div class="xScroll d-flex">
        <first
          :submitValidate="submitStart"
          :sectionName="sections[0]"
          sectionPosition="0"
          submitName="Crear Usuario"
          @moveForm="moveForm($event)"
          @submitForm="submit()"
        ></first>
      </div>
    </form>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.xScroll {
  margin-left: 0%;
  transition: 0.3s ease-in-out;
}
.mainForm {
  z-index: 8;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
</style>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import passContainer from "@/components/form/passContainer";
import titleForm from "@/components/form/Title";

import { validateFormData } from "@/modules/tools";

import first from "@/views/user/form/1";

export default {
  name: "User Create",
  data() {
    return {
      sections: ["Datos Básicos"],
      activeSection: "",
      submitStart: false,
      showValidate: false,
      branchName: ""
    };
  },
  components: {
    Legal,
    passContainer,
    titleForm,
    first
  },
  async created() {
    this.activeSection = this.sections[0];
    const store = new Storage();
    const planData = await store.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    const employeeData = await store.getData("employee");
    const totalEmployee = employeeData.length;
    const limitEmployee = planData.restrictions.employees;

    if (totalEmployee >= limitEmployee) {
      this.$router.go(-1);
    }

    store.getData("branch", this.$route.params._id).then(branch => {
      store.getData("city", branch.city).then(city => {
        this.branchName = city.name;
      });
    });
  },
  methods: {
    submit() {
      const userForm = document.getElementById("userForm");
      const input = userForm.getElementsByTagName("input");
      const select = userForm.getElementsByTagName("select");
      let submit = true;

      this.submitStart = !this.submitStart;
      this.showValidate = true;

      for (let i = 0; i < input.length; i++) {
        if (!validateFormData(input[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < select.length; i++) {
        if (!validateFormData(select[i])) {
          submit = false;
        }
      }

      if (submit) {
        this.sendData(userForm);
      }
    },
    sendData(form) {
      const store = new Storage();
      const formData = new FormData(form);

      Swal.fire({
        icon: "warning",
        title: "Creando Usuario",
        html:
          "Por favor espere mientras se crea el usuario</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressDataForm" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      const data = {
        name: formData.get("name"),
        rol: formData.get("rol"),
        IDType: formData.get("IDType"),
        ID: formData.get("ID"),
        email: formData.get("email"),
        branch: this.$route.params._id,
        employee: store.IDGenerator()
      };

      store
        .insertData("optima", "user", [data])
        .then(userData => {
          store
            .downloadData("optima", "employee", {
              where: "_id=" + userData[0].employee
            })
            .then(() => {
              this.endSubmit(true);
            });
        })
        .catch(error => {
          console.log(error);
          this.endSubmit(false);
        });
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Usuario creado",
          text: "Todos los datos han sido sincronizados"
        }).then(result => {
          this.$router.go(-1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al crear el usuario",
          text:
            "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
        }).then(result => {
          this.$router.go(-1);
        });
      }
    }
  }
};
</script>
